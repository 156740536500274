import {
  addTagTypes,
  baseClientApi as api,
  DownloadJobApiV1JobsJobIdUploadGetApiArg,
  DownloadJobApiV1JobsJobIdUploadGetApiResponse,
  DownloadPartApiV1PartsPartIdUploadedGetApiArg,
  DownloadPartApiV1PartsPartIdUploadedGetApiResponse,
  GetDeviceStatusApiV1DevicesSerialNumberStatusGetApiArg,
  GetDeviceStatusApiV1DevicesSerialNumberStatusGetApiResponse,
  GetOrganisationBillingSessionApiV1OrganisationsOrgIdGetApiArg,
  GetOrganisationBillingSessionApiV1OrganisationsOrgIdGetApiResponse,
} from './baseClientApi'
import { emptyApi } from './emptyApi'

const CreateTags = <T, V>(
  tag: V,
  result: T | T[] | undefined,
  selectId: (item: T) => string,
  isList?: boolean,
) =>
  result
    ? [
        ...(Array.isArray(result)
          ? result.map((item) => ({
              type: tag,
              id: selectId(item),
            }))
          : [
              {
                type: tag,
                id: selectId(result),
              },
            ]),
        ...(isList ? [{ type: tag, id: 'LIST' }] : []),
      ]
    : [tag]

const clientApi = api.enhanceEndpoints({
  addTagTypes: ['users-meta', 'users-bfp', 'users-reset', 'user-role'],
  endpoints: {
    getRolesApiV1UsersRolesGet: {
      providesTags: ['users-meta'],
    },
    removeBfpUserApiV1UsersUserIdBlockBfpDelete: {
      invalidatesTags: ['users-bfp'],
    },
    getBfpUserApiV1UsersUserIdBlockBfpGet: {
      providesTags: ['users-bfp'],
    },
    resetUserApiV1UsersUserIdResetPut: {
      invalidatesTags: ['users-reset'],
    },
    getUserRolesApiV1UsersUserIdRolesGet: {
      providesTags: (result, error, args) => [
        { type: 'user-role', id: args.userId },
      ],
    },
    removeRoleApiV1UsersUserIdRolesRoleDelete: {
      invalidatesTags: (result, error, args) => [
        { type: 'user-role', id: args.userId },
      ],
    },
    addRoleApiV1UsersUserIdRolesRolePatch: {
      invalidatesTags: (result, error, args) => [
        { type: 'user-role', id: args.userId },
      ],
    },
    signContextApiV1SignContextPatch: {
      invalidatesTags: undefined,
    },
    getDevicesApiV1DevicesGet: {
      providesTags: (result, error, arg) =>
        CreateTags('devices', result?.content, (item) => item.thing_name, true),
    },
    getDevicesWithStatusApiV1DevicesStatusGet: {
      providesTags: (result, error, arg) =>
        CreateTags('devices', result?.content, (item) => item.thing_name, true),
    },
    getDeviceApiV1DevicesSerialNumberGet: {
      providesTags: (result, error, arg) =>
        CreateTags('devices', result, (item) => item.thing_name),
    },
    getDeviceStatusApiV1DevicesSerialNumberStatusGet: {
      providesTags: (result, error, arg) =>
        CreateTags('devices', result, (item) => item.thing_name),
    },
    getJobsApiV1JobsGet: {
      providesTags: (result, error, arg) =>
        CreateTags('jobs', result?.content, (item) => item.id.toString(), true),
    },
    getJobApiV1JobsJobIdGet: {
      providesTags: (result, error, arg) =>
        CreateTags('jobs', result, (item) => item.id.toString()),
    },
    getPartApiV1PartsPartIdGet: {
      providesTags: (result, error, arg) =>
        CreateTags('parts', result, (item) => item.id.toString()),
    },
    getPartsApiV1PartsGet: {
      providesTags: (result, error, arg) =>
        CreateTags(
          'parts',
          result?.content,
          (item) => item.id.toString(),
          true,
        ),
    },
  },
})

const downloadMutationApi = emptyApi
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      downloadPartApiV1PartsPartIdUploadedGet: build.mutation<
        DownloadPartApiV1PartsPartIdUploadedGetApiResponse,
        DownloadPartApiV1PartsPartIdUploadedGetApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/parts/${queryArg.partId}/uploaded`,
        }),
      }),
      downloadJobApiV1JobsJobIdUploadGet: build.mutation<
        DownloadJobApiV1JobsJobIdUploadGetApiResponse,
        DownloadJobApiV1JobsJobIdUploadGetApiArg
      >({
        query: (queryArg) => ({ url: `/api/v1/jobs/${queryArg.jobId}/upload` }),
      }),
      getDeviceWithStatusGet: build.mutation<
        GetDeviceStatusApiV1DevicesSerialNumberStatusGetApiResponse,
        GetDeviceStatusApiV1DevicesSerialNumberStatusGetApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/devices/${queryArg.serialNumber}/status`,
        }),
      }),
      getOrganisationBillingSessionApiV1OrganisationsOrgIdGet: build.mutation<
        GetOrganisationBillingSessionApiV1OrganisationsOrgIdGetApiResponse,
        GetOrganisationBillingSessionApiV1OrganisationsOrgIdGetApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/organisations/${queryArg.orgId}/billing/session`,
        }),
      }),
    }),

    overrideExisting: true,
  })

enum JobStatus {
  PENDING = 0,
  CREATED = 1,
  PROCESSING = 2,
  TO_ACCEPT = 3,
  PREPARING_TO_SEND = 4,
  PRINT_PENDING = 5,
  PRINTING = 6,
  PRINTED = 7,
  FAILED = 8,
  REJECTED = 9,
  OFFLINE = 10,
  CANCELLED = 11,
}

export const REFETCH_TIMEOUT = 4000

export const {
  useDownloadPartApiV1PartsPartIdUploadedGetMutation,
  useDownloadJobApiV1JobsJobIdUploadGetMutation,
  useGetDeviceWithStatusGetMutation,
  useGetOrganisationBillingSessionApiV1OrganisationsOrgIdGetMutation,
} = downloadMutationApi

export * from './baseClientApi'
export { clientApi, JobStatus }
