import { useEffect } from 'react'

export const PricingTable = ({
  clientSessionSecret,
}: {
  clientSessionSecret: string
}) => {
  useEffect(() => {
    const script = document.createElement('script')
    script.src = 'https://js.stripe.com/v3/pricing-table.js'
    script.async = true
    document.body.appendChild(script)
    return () => {
      document.body.removeChild(script)
    }
  }, [])
  return (
    <stripe-pricing-table
      pricing-table-id={import.meta.env.VITE_PRICING_TABLE_ID}
      publishable-key={import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY}
      customer-session-client-secret={clientSessionSecret}
    ></stripe-pricing-table>
  )
}
