import { useAuth0 } from '@auth0/auth0-react'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import MenuIcon from '@mui/icons-material/Menu'
import { Divider, MenuItem, useTheme } from '@mui/material'
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar'
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Toolbar from '@mui/material/Toolbar'
import { styled, Theme } from '@mui/material/styles'
import React, { FC, PropsWithChildren, Suspense, useState } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { ensureError, localizeError } from '../../helpers'
import { useOrg } from '../../hooks/org'
import { useUser } from '../../hooks/user'
import { useGetOrganisationBillingSessionApiV1OrganisationsOrgIdGetMutation } from '../../store/clientApi'
import { DialogBox } from '../DialogBox'
import { Loading } from '../Loading'
import { LanguageSelector } from '../LocaleSelector/LocaleSelector'
import Logo from '../Logo/Logo'
import { Menu } from '../Menu/Menu'
import { PricingTable } from '../PricingTable/PricingTable'
import { Profile } from '../Profile/Profile'
import { UserDetails } from '../UserDetails'

interface DashboardLink {
  name: string
  link: string | React.JSX.Element
  icon: React.ReactNode
}

interface DashboardProps {
  primaryLinks: DashboardLink[]
  secondaryLinks?: DashboardLink[]
  footerLinks: DashboardLink[]
}

const NavList = (
  nav: DashboardLink,
  index: number,
  open: boolean,
  isSelected: boolean,
) => {
  const navigate = useNavigate()
  const Icon = () => <>{nav.icon}</>
  return (
    <ListItem key={`${nav.name}-${index}`} disablePadding>
      {typeof nav.link === 'string' ? (
        <ListItemButton
          selected={isSelected}
          sx={{
            flexDirection: 'column',
            color: (theme: Theme) =>
              isSelected ? theme.palette.primary.main : theme.surface.onVariant,
          }}
          onClick={() => navigate(nav.link as string)}
        >
          <ListItemIcon
            sx={{
              display: 'flex',
              justifyContent: 'center',
              color: (theme: Theme) =>
                isSelected
                  ? theme.palette.primary.main
                  : theme.surface.onVariant,
            }}
          >
            <Icon />
          </ListItemIcon>
          <ListItemText
            primary={nav.name}
            sx={{ fontSize: { xs: '0.8em', md: '1em' } }}
            disableTypography
          />
        </ListItemButton>
      ) : (
        nav.link
      )}
    </ListItem>
  )
}

interface AppBarProps extends MuiAppBarProps {
  open?: boolean
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 10,
}))

const CustomDrawerDynamicWidths = (theme: Theme) => ({
  [theme.breakpoints.up('sm')]: {
    width: '80px',
  },
  [theme.breakpoints.up('md')]: {
    width: '100px',
  },
  [theme.breakpoints.up('lg')]: {
    width: '120px',
  },
})

const CustomDrawer = styled(Drawer)(({ theme }) => ({
  ...CustomDrawerDynamicWidths(theme),
  '& .MuiDrawer-paper': {
    ...CustomDrawerDynamicWidths(theme),
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.shorter,
    }),
  },
  flexShrink: 0,
  overflowX: 'hidden',
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
}))

const ProfilePopup = ({
  open,
  setOpen,
}: {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  const { t } = useTranslation('users')
  return (
    <DialogBox
      title={t('common:title.profile')}
      open={open}
      setOpen={setOpen}
      args={undefined}
      message={<UserDetails close={() => setOpen(false)} />}
      onClose={() => {
        return
      }}
      sx={{ '.MuiPaper-root': { width: '100%', margin: '16px' } }}
      hideConfirm
    />
  )
}

const Dashboard: FC<PropsWithChildren<DashboardProps>> = (props) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const UNAVAILABLE = t('common:loading.unavailable')
  const { logout } = useAuth0()
  const [open, setOpen] = useState(false)
  const [mobileOpen, setMobileOpen] = useState(false)
  const [isClosing, setIsClosing] = useState(false)
  const { picture, data: userData, roles: userRoles } = useUser()
  const { orgId } = useOrg()
  const [openSubscriptionOptions, setOpenSubscriptionOptions] = useState(false)
  const [clientSessionSecret, setClientSessionSecret] = useState('')

  const [getBillingSession] =
    useGetOrganisationBillingSessionApiV1OrganisationsOrgIdGetMutation()

  const [profileOpen, setProfileOpen] = useState(false)

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const openMenu = Boolean(anchorEl)
  const locationPath = useLocation().pathname
  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const handleMobileDrawerClose = () => {
    setIsClosing(true)
    setMobileOpen(false)
  }

  const handleMobileDrawerTransitionEnd = () => {
    setIsClosing(false)
  }

  const handleMobileDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen)
    }
  }

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  const openBillingPortal = async () => {
    if (orgId !== undefined) {
      try {
        const session = await getBillingSession({
          orgId: orgId,
        }).unwrap()

        if (session.url !== undefined) {
          window.open(session.url, '_blank')?.focus()
        } else if (session.client_secret !== undefined) {
          setClientSessionSecret(session.client_secret)
          setOpenSubscriptionOptions(true)
        }
      } catch (err) {
        const error = ensureError(err)
        toast.error(localizeError(t, error))
      }
    }
  }

  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: open ? 'flex-end' : 'center',
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  }))

  const DrawerContent = (
    <Box
      component="div"
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'flex-start',
        bgcolor: theme.palette.background.default,
      }}
    >
      <DrawerHeader>
        <IconButton
          color="inherit"
          aria-label={t('common:label.openDrawer')}
          onClick={handleDrawerOpen}
          edge="start"
          sx={{
            display: {
              sm: 'flex',
              xs: 'none',
            },
            margin: 0,
            ...(open && { display: 'none' }),
          }}
        >
          <MenuIcon />
        </IconButton>
        <IconButton
          onClick={handleDrawerClose}
          sx={{
            ...(!open && { display: 'none' }),
          }}
        >
          <ChevronLeftIcon />
        </IconButton>
      </DrawerHeader>
      <List>
        {props.primaryLinks.map((e, i) =>
          NavList(e, i, open, locationPath === e.link),
        )}
      </List>
      <List>
        {props.secondaryLinks?.map((e, i) =>
          NavList(e, i, open, locationPath === e.link),
        )}
      </List>
      <Box sx={{ flexGrow: 1 }} component="div"></Box>
      <Box justifyContent={'flex-end'} component="div">
        {props.footerLinks?.map((e, i) =>
          NavList(e, i, open, locationPath === e.link),
        )}
      </Box>
    </Box>
  )

  return (
    <Box sx={{ display: 'flex', height: '100%' }} component="div">
      <AppBar
        position="fixed"
        open={open}
        elevation={0}
        sx={{
          bgcolor: theme.palette.background.default,
          color: 'black',
          '.MuiToolbar-root': {
            paddingLeft: '16px',
            paddingRight: '16px',
          },
        }}
      >
        <Toolbar>
          <IconButton
            aria-label={t('common:label.openDrawer')}
            onClick={handleMobileDrawerToggle}
            edge="start"
            sx={{
              display: {
                sm: 'none',
                xs: 'block',
              },
              marginRight: '16px',
            }}
          >
            <MenuIcon />
          </IconButton>
          <Logo width="120px" />
          <Box
            component="div"
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <IconButton id="avatar-menu" onClick={handleMenuClick}>
              <Avatar
                sx={{
                  height: '36px',
                  width: '36px',
                }}
                alt={t('users:alt.profile')}
                src={picture}
              />
            </IconButton>
          </Box>
          <Menu
            innerProps={{
              id: 'account-menu',
              anchorEl: anchorEl,
              open: openMenu,
              onClose: handleMenuClose,
              onClick: handleMenuClose,
            }}
            noPadding
            hideArrow
          >
            <MenuItem disableTouchRipple>
              <Profile
                picture={picture ?? ''}
                name={userData?.name ?? UNAVAILABLE}
                info={
                  /*
                  role keys
                  t('role.accountant')
                  t('role.admin')
                  t('role.designer')
                  t('role.owner')
                  t('role.technician')
                  */
                  userRoles && userRoles.length > 0
                    ? t(`users:role.${userRoles[0].name}`, {
                        defaultValue: userRoles[0].name,
                      })
                    : UNAVAILABLE
                }
              />
            </MenuItem>
            <Divider />
            <MenuItem id="profile-button" onClick={() => setProfileOpen(true)}>
              {t('title.profile')}
            </MenuItem>
            <MenuItem
              id="manage-subscriptions"
              onClick={() => openBillingPortal()}
            >
              {t('title.manageSubscription')}
            </MenuItem>
            <Divider />
            <MenuItem id="language-button" disableTouchRipple>
              <LanguageSelector size="small" />
            </MenuItem>
            <Divider />
            <MenuItem
              onClick={async () => {
                handleMenuClose()
                await logout({
                  logoutParams: { returnTo: window.location.origin },
                })
              }}
            >
              {t('common:button.logout')}
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
      <CustomDrawer
        variant="permanent"
        open={true}
        sx={{
          display: { xs: 'none', sm: 'block' },
          '& .MuiPaper-root': { borderRightWidth: '0px' },
        }}
      >
        {DrawerContent}
      </CustomDrawer>
      <Drawer
        variant="temporary"
        onTransitionEnd={handleMobileDrawerTransitionEnd}
        onClose={handleMobileDrawerClose}
        open={mobileOpen}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: '120px' },
        }}
      >
        {DrawerContent}
      </Drawer>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 1,
          bgcolor: theme.palette.background.default,
          paddingTop: '60px',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
          boxSizing: 'border-box',
          overflowX: 'hidden',
        }}
      >
        <Suspense fallback={<Loading />}>{props.children}</Suspense>
      </Box>
      <ProfilePopup open={profileOpen} setOpen={setProfileOpen} />
      <DialogBox
        args={null}
        title={t('common:title.subscriptionOptions')}
        message={<PricingTable clientSessionSecret={clientSessionSecret} />}
        open={openSubscriptionOptions}
        setOpen={setOpenSubscriptionOptions}
        onClose={() => {
          return
        }}
        hideConfirm
        sx={{
          '.MuiPaper-root': {
            width: {
              xs: '100%',
              sm: '80%',
              md: '80%',
              lg: '80%',
              xl: '80%',
            },
            maxWidth: 'unset',
            margin: '16px',
          },
        }}
      />
    </Box>
  )
}

export type { DashboardLink }
export { Dashboard }
